import './App.css'
import {Image, Container, Row,Col, Card, CardDeck } from 'react-bootstrap';
import about_me_photo from './images/P1010730.jpg'; 
import abraham_lake_photo from './images/P1010746.jpg';
import vancouver_photo from './images/LRM_EXPORT_27542315759752_20190308_080109132.jpeg'
import temagami_photo from './images/P1010546.jpg';
import construction_photo from './images/Page_Under_Construction.png';
import ReactPlayer from "react-player";

//import { Container, Row, Col } from 'reactstrap';




function App() {
  function get_joke(){
    var jokes = [
      "Where does Captain Hook get his replacements? The second hand store",
      "What do you call an illegally parked frog? Toad",
      "What do you call an alligator in a vest? An investigator",
      "Why did the wizards wife have hickeys on her neck? Cause he was a neck-romancer",
      "How does a train eat? It goes chew chew",
      "What do you call a hippopotamus in a phone booth? Stuck"
    ]
    return jokes[Math.floor(Math.random() * jokes.length)];
  
  }
  return (
    <div className="App">
      <header className="App-header">
        <Container className="" fluid>
          <Row className="mt-5 pt-5">
            <Col md={{ span: 6, offset: 2 }}>
                <h1>I am Jordan Stirling</h1>
                <h3>Engineer, Hobbyist, Athlete</h3>
            </Col>
          </Row>
        </Container>
      </header>
      <hr  class="my-5 mx-5 px-5" style={{
          color: '#000000',
          backgroundColor: '#000000',
          height: .5,
          borderColor : '#000000'
      }}/>
      <div className="About-Me">
        <Container>
          <Row className="align-items-center">
            <Col className="mr-5 ">
              <Row>
                <h3>About Me</h3>
              </Row>
              <Row className="mt-5">
                <p>
I am a full stack engineer by day, and a outdoor loving, fitness enthusiast by night.

I am a builder by nature. I love taking things apart, figuring out how they work, and trying to put them back together - needless to say, I'm really good at the first two and so-so on the last. I love learning new things, and love to dive head first into new problems.

This leads to me having no shortage of hobbies, and always on the look out for new ones. Just so you have an idea, I have tried my hand at painting, fitness expert, salsa dancing, youtuber, mountain biking, weight lifting, video games, stock trading... you name it.</p>
              </Row>
            </Col>
            <Col>
              <div className="w-auto">
                <Image src={about_me_photo} rounded fluid/>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <hr  class="my-5 mx-5 px-5" style={{
          color: '#000000',
          backgroundColor: '#000000',
          height: .5,
          borderColor : '#000000'
      }}/>
      <div className="Fav-Youtube">
        <Container>
          <Row className="align-items-center ">
            <Col>
              <Row className="justify-content-md-center">
                <h3>My Favourite Youtube Video</h3>
              </Row>
              <Row className="justify-content-md-center">
              <h5> (Be Careful, He Swears)</h5>
              </Row>
              <Row className="mt-3 justify-content-md-center">
                <p>While I have a lot of favourites, this is the one I recite on an almost daily basis.</p>
              </Row>
              <Row className="mt-5 justify-content-md-center">
                <ReactPlayer url="https://www.youtube.com/watch?v=KQ5bUkk6iW0"/>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <hr  class="my-5 mx-5 px-5" style={{
          color: '#000000',
          backgroundColor: '#000000',
          height: .5,
          borderColor : '#000000'
      }}/>
      <div className="Pics-I-Took">
        <Container>
          <Row className="align-items-center justify-content-md-center">
            <h3>Some Photos I Took That I Found Cool</h3>
          </Row>
          <Row>
            <CardDeck className="">
              <Card>
                <Card.Img variant="top" src={abraham_lake_photo} />
                <Card.Body>
                  <Card.Title>Abraham Lake</Card.Title>
                  <Card.Text>
                    I spent a weekend camping at Abraham Lake, Alberta surrounded by mountains and forests, on Sept 2020. 
                    The smoke from California wildfires had come all the way to the lake and made visibility very poor the entire weekend.
                    This was taken on our final morning and was the first time being able to see anything.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img variant="top" src={vancouver_photo} />
                <Card.Body>
                  <Card.Title>Vancouver Waterfront</Card.Title>
                  <Card.Text>
                    I spent all day walking around Vancouver. This day, I ended up walking around 25-30 km, from Downtown to Capilano Suspension Bridge, at the park, then back home.
                    This was at the waterfront on my way home, and was so charming.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img variant="top" src={temagami_photo} />
                <Card.Body>
                  <Card.Title>Lake Temagami</Card.Title>
                  <Card.Text>
                    This was by far one of the most brilliant sunsets I have ever experienced. 
                    The whole sky was the flourescent orange. After a whole day of portaging, it was the perfect way to wind down.
                    Id tell you where it was, but then I'd have to kill you. ;) Just Kidding. FBI agent, if youre watching, I swear I wouldnt.
                  </Card.Text>
                </Card.Body>
              </Card>
            </CardDeck>
          </Row>
        </Container>        
      </div>
      <hr  class="my-5 mx-5 px-5" style={{
          color: '#000000',
          backgroundColor: '#000000',
          height: .5,
          borderColor : '#000000'
      }}/>
      <div>
        <Container>
          <Row className="align-items-center align-bottom">
            <h5>{get_joke()}</h5>
          </Row>
        </Container>
      </div>
      <hr  class="my-5 mx-5 px-5" style={{
          color: '#000000',
          backgroundColor: '#000000',
          height: .5,
          borderColor : '#000000'
      }}/>
      <div>
        <Container>
          <Row className="align-items-center align-bottom">
            <h3>Personal Projects:</h3>
          </Row>
          <Row align-items-center justify-content-md-center>
            <Image src={construction_photo} rounded fluid/>

          </Row>
        </Container>
      </div>
    </div>
  );
}

export default App;
